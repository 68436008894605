import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import '../sass/productores_salas.scss';
import '../sass/general.scss';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { getLangValue } from '../../api/config';

const SmartTicket = () => {
    const [productYSalasTitle] = useState<string>(`${getLangValue("product-salas-title")}`);

    
    
    useEffect(()=>{

        let alternateAr = document.getElementById("alternate_ar") as HTMLLinkElement;
        alternateAr.href = "https://www.entradaweb.com.ar/productores-y-salas";

        let alternateEs = document.getElementById("alternate_es") as HTMLLinkElement;
        alternateEs.href = "https://www.entradaweb.es/productores-y-salas";

        let alternateDefault = document.getElementById("alternate_default") as HTMLLinkElement;
        alternateDefault.href = "https://www.entradaweb.com/productores-y-salas";

        document.title = productYSalasTitle;

        setTimeout(()=>{
            window.scrollTo(0, 0);
        },500)
        const handleScroll = () => {
            const divDestino = document.getElementById('quienes-hacemos');
            const fixedElement = document.getElementById('scroll-element');

            if (!divDestino || !fixedElement) return;

            const divDestinoTop = divDestino.getBoundingClientRect().top;
            const fixedElementHeight = fixedElement.clientHeight;
            const isFixedElementAboveDiv = divDestinoTop >= fixedElementHeight;

          };

          window.addEventListener('scroll', handleScroll);

          return () => {
            window.removeEventListener('scroll', handleScroll);
          };

    },[])

    return (
        <div className='productores-y-salas'>
            <Container>

                <Col className='text-start mt-4' md={{ offset: 2, size: 8 }}>
                    <h1 className="text_2 mb-4">Entradaweb QR y Smart Ticket</h1>
                    <h2 className="text_2 text-center">Preguntas Frecuentes</h2>
                    <h2 className="text_2 mt-4">Qué es QR y Smart Ticket?</h2>
                    <Row>
                        <Col>
                                <p className="text_3">
                                    Entradaweb soporta múltiples maneras de entregar las entradas compradas en nuestra sitio. Podemos enviarte entradas con QR estático o podemos utilizar Smart Ticket
                                    Un Smart Ticket es una entrada o ticket electrónico único y seguro que puedes recibir y almacenar en nuestra app hasta el momento de usarlo o hasta que lo transfieras a otra persona.
                                    Esta app es sólo para administrar tus entradas compradas o recibidas como invitación  a través de los servicios de Entradaweb.
                                </p>
                        </Col>
                    </Row>
                    <Row className='mt-3 mb-4'>
                        <Col md={7} className='text-end'>
                            <h2 className="text_2">Cómo funciona el Smart Ticket de Entradaweb?</h2>
                        </Col>
                        <Col>
                            <p>
                                Cuando compres tus entradas o tickets para un evento (y si dicho evento está configurado para entregar las entradas como Smart Ticket) vas a recibir en tu correo electrónico una confirmación de compra y verás los tickets en nuestra app instalada en tu celular.
                                También de acuerdo a cómo esté configurado el uso de Smart Ticket en el evento, podrás transferir un número determinado de veces tus tickets o entradas.
                                El QR que te permite el acceso al evento, y por razones de seguridad, será visible sólo el día del evento. Esta funcionalidad está disponible aunque tu celular no esté conectado a internet.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Container>
            <Col className='back_2'>
                <Container className='back_2 pt-3 pb-3 position-relative'>
                    <div className='position-absolute' style={{ "width": "20%", "left": "-10%", "top": "25px" }}>
                        <img src="/images/ventajas-back.svg" alt="" style={{ "opacity": "0.4" }} />
                    </div>
                    <Col md={{ offset: 2, size: 8 }}>
                            <h2 className="text_1 col-md-8 mt-3 mb-5">Como instalo Entradaweb QR y Smart Ticket?</h2>
                        <Row>
                            <Col>
                                    <p className='text_1'>Desde tu celular instalas la app desde la <a href='https://play.google.com/store/apps/details?id=com.entradaweb' className="link-smart-link">Play Store (Android)</a>o desde la <a href='https://apps.apple.com/us/app/entradaweb-qr-y-smart-ticket/id6736376421' className="link-smart-link">App Store (IPhone).</a>Abres la app y te solicitará tu email (el mismo que utilizaste en nuestro sitio para comprar tus entradas). Acto seguida vas a recibir un email donde te enviamos un Código de Verificación que deberás ingresar para completar el acceso a la App.
                                    Puede ocurrir también que si no tenemos registrados tus datos te solicitemos Nombre y Apellido y DNI. </p>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Col>
            <Container>
                <Col>
                    <Container>
                        <Col md={{ offset: 2, size: 9 }} className='mt-5'>
                            <Row>
                                <Col className='text-end' md='5'>
                                    <h2 className="text_2">Cómo transfiero Entradas?</h2>
                                </Col>
                                <Col>
                                    <p>
                                        Sobre tus entradas haces click en los 3 puntitos y accedes al botón "Transferir"
                                        Si la opción Transferir no está disponible para tu evento, entonces pueden ocurrir dos cosas: <br/>

                                        1. el evento no permite transferencia de entradas.<br/>
                                        2. la cantidad de transferencias permitidas ya fue utilizada. <br/>
                                        Cada Productor u Organizador decide para su evento si acepta o no transferencia de entradas y además fija la cantidad de transferencias que se pueden realizar.
                                        Voviendo al proceso de transferencia, haces click en "Transferir" y automáticamente la app te llevará a la pantalla donde podrás seleccionar las entradas que quieres enviar. Activas la transferencia y e ingresas la dirección de correo electrónico de a quién deseas enviar las entradas (chequea que tengas la dirección correcta). Luego reconfirmas y listo!<br/>
                                        En forma automática la persona receptora de las entradas recibirá un correo electrónico de aviso y en ese mismo email un link al que debe acceder para confirmar y terminar la transferencia. Tiene 24hs para hacer esto.
                                        Una vez confirmada la transferencia las entradas pasarán de tu app a la de la otra persona.
                                    </p>
                                    <h2 className="text_3 mb-3">Acceso al evento</h2>
                                    <p>
                                        Como ya dijimos, la app contiene tus entradas y el mismo día del evento generará para cada una de ellas el QR que te permitirá acceder al mismo.<br/> 
                                        Dicho QR es único, irrepetible y sólo estará disponible en la app en tu celular.<br/>
                                        El QR es dinámico, o sea que cambia cada 30 segundos y quedará automáticamente desactivado una vez que sea leído por el Control de Acceso.<br/>
                                        Puedes usar el QR de tus entradas aún sin conexión a internet.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5} className='text-end'>
                                        <p>
                                            Si extravías o te roban tu celular, no te preocupes que tus tickets o entradas te estarán esperando en nuestra app. Solamente inicia sesión en cualquier otro celular con tu Usuario y la sesión anterior (que tenías abierta en el dispositivo que ya no tienes) se cerrará automáticamente.<br/>
                                            Como medida de seguridad recibirás en tu casilla de correo un código de verificación. Lo ingresas en la app para completar tu inicio de sesión y accederás nuevamente a tus entradas.<br/>
                                            Debes tratar de hacer esto lo más rápido que puedas.
                                        </p>
                                </Col>
                                <Col md={5}>
                                        <h2 className="text_2">Pérdida de celular</h2>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Col>
            </Container>
            {<Container>
                <Col className='text-start mt-4' md={{ offset: 2, size: 8 }}>
                    <p>Puedes conocer más sobre Entradaweb QR y Smart Ticket mirando nuestro video explicativo en <a href='https://www.youtube.com/watch?v=cBb0ElbOBs8' className='link-smart-link-azul'>YouTube</a></p>
                </Col>
            </Container>}
        </div>
    );
};

export default SmartTicket;