import React, { useEffect, useState } from 'react';
import { getDebtByCode } from '../../api/purchase';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { Col, Container, Row } from 'reactstrap';

const PagoValidar = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        (async () => {
            if (code) {
                const res = await getDebtByCode(code);
                if (res) {
                    localStorage.setItem('token', res.token);
                    navigate(`/pagos/${res.eventHash}/step/2`)
                } else {
                    setError("algo");
                }
            }
        })()
    }, [navigate])

    return (
        <div className='vh-100'>
            {!error && <>
                <h1 className='mt-5 text_3 general-title text-center'><b>Estamos Procesando tu solicitud</b></h1>
                <h1 className='text_3 general-title text-center'><b>Por favor...</b></h1>
                <h1 className='text_3 general-title text-center'><b>Aguarda unos segundos...</b></h1>
                <div className='d-flex justify-content-center'>
                    <Spinner className='text-center' style={{ "top": "50%"}} />
                </div>
            </>}
            {error &&
                <>
                        <div className='mt-5'></div>
                        <Row>
                            <Col xs={{ size: 4, offset: 5 }} className='mt-4'>
                                <img src="/images/icono-busqueda-01.svg" alt="icono de busqueda sin resultados" className='img-fluid icon-without-result' />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'><h1 className='text_3 fs-22 pt-3'>¡No encontramos ningún resultado!</h1></Col>
                        </Row>
                        <Row>
                            <Col className='text-center ps-4 pe-4 fs-12 ps-5 pt-2' style={{ "lineHeight": "22px" }}>
                                <p>Verifica la información e intentalo nuevamente</p>
                            </Col>
                        </Row>
                </>
            }
        </div>
    );
};

export default PagoValidar;