import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
type propiedades = {
    paymentMethod: string,
    additionalCode: string,
    eventType: number
}

const Cash = (props: propiedades) => {

    const { paymentMethod, additionalCode, eventType } = props;
    const metodoDePago = paymentMethod === 'pagofacil' ? 'Pago Fácil' : paymentMethod === 'rapipago' ? 'Rapipago' : '';
    const navigate = useNavigate();

    const goBackHandler = () => {
        navigate(`/`)
    }

    return (
        <>
            <h2 className='title text_3 mt-4 text-start'>¡Generamos tu Código de Pago en Efectivo!</h2>
            <div className='text-start'>
                <p>Elegiste ir a pagar a <strong>{metodoDePago}</strong>. Presentá este código de <strong>Mercado Pago</strong> por ventanilla para que te cobren.</p>
                <p className='codigo-mercado-pago mb-0'>Código de Mercado Pago:</p>
                <p className='codigo-mercado-pago ps-5'><strong>{additionalCode}</strong></p>
                <p className='mb-0'>
                    <strong>IMPORTANTE:</strong> debes realizar el pago antes de que transcurran las 24hs.
                </p>
                {eventType === 0 && 
                    <>
                    
                        <p className='mb-0'>
                            No te preocupes que tus lugares están reservados hasta que <strong>Mercado Pago</strong> nos confirme tu pago.
                        </p>
                        <p style={{"fontWeight": "bold"}}>
                            Si se vence el plazo de 24hs. los lugares se liberarán y deberás realizar de nuevo tu compra.
                        </p>
                    </>
                } 
                
                <Row className='mb-5'>
                    <Col md={{ size: 8, offset: 0 }} className='d-flex align-items-center justify-content-center mt-2'>
                        <Button className='back_3 border-0 mt-2 fw-bold ps-4 pe-4' onClick={goBackHandler}>
                            IR AL INICIO
                        </Button>
                    </Col>
                </Row>
            </div>

        </>
    );
};

export default Cash;