import { useEffect, useState } from 'react';
import { fetchEventoSeleccionado, fetchCambiarNombrePaso, setVendedor, irAlPasoDos, resetPasosCompra, irAlPasoTres, fetchDetalleCompra, irAlPasoCuatro } from '../../reducer/events-reducer';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { eventoSeleccionado, datosCompra, PurchaseData } from '../../types/types';
import '../sass/evento_seleccionado.scss';
import '../sass/general.scss';
import {
    Container,
} from 'reactstrap';
import PasosCompra from '../components/eventos/pasosCompra';
import PasoUnoEvento from '../components/eventos/PasoUnoEvento';
import PasoDosEvento from '../components/eventos/PasoDosEvento';
import PasoTresEvento from '../components/eventos/PasoTresEvento';
import PasoCuatroEvento from '../components/eventos/PasoCuatroEvento'
import { useNavigate } from 'react-router-dom';
import Timeout from '../components/compra/Timeout';
import { parseJWt } from '../../utilities/utilities';
import BarraSuperior from '../components/eventos/BarraSuperior';
import FueraDeVenta from '../components/eventos/FueraDeVenta';
import { getLangValue, setOgValues } from '../../api/config';

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
        pasoActualCompra: string;
        idVendedor: string;
        datosCompraPasoDos: PurchaseData;
        detalleCompra: datosCompra,
        fueraDeVenta: boolean
    }
}
interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    fetchEventoSeleccionado: (hashEvent: string) => void;
    fetchCambiarNombrePaso: (init: boolean) => void;
    setVendedor: (vendedor: string | null) => void;
    pasoActualCompra: string,
    idVendedor: string,
    irAlPasoDos: (code: string, seller: string, referrer: string | null, cardNumber: string | null, onlyReload?: boolean | undefined) => {};
    resetPasosCompra: () => void;
    datosCompraPasoDos: PurchaseData;
    irAlPasoTres: () => {};
    detalleCompra: datosCompra
    fetchDetalleCompra: () => any
    irAlPasoCuatro: (paymentType: string) => void;
    fueraDeVenta:boolean
}

const EventoSeleccionado = (props: propiedades) => {
    const { hashEvent, pasoCompra } = useParams();
    const [timeOut, setElTimeOut] = useState<boolean>(false);
    const { fetchEventoSeleccionado, eventoSeleccionado, fetchCambiarNombrePaso, setVendedor, pasoActualCompra, idVendedor, irAlPasoDos, resetPasosCompra, irAlPasoTres, detalleCompra, fetchDetalleCompra, irAlPasoCuatro, fueraDeVenta } = props;
    const navigate = useNavigate();
    const lang = getLangValue('lang');

    useEffect(() => {
        fetchEventoSeleccionado(hashEvent!)
    }, [fetchEventoSeleccionado, hashEvent])

    useEffect(() => {
        if (eventoSeleccionado && eventoSeleccionado.sessions && eventoSeleccionado.sessions.length > 1) {
            fetchCambiarNombrePaso(true);

        } else if (eventoSeleccionado && eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1) {
            fetchCambiarNombrePaso(false);
        }
        if (window.location.pathname.split('/step/')[1] === '3' || window.location.pathname.split('/step/')[1] === '4') {
            fetchDetalleCompra()
        }
        if(eventoSeleccionado && eventoSeleccionado.eventType == 1) {
            navigate(`/pagos/${hashEvent}/step/1`)
        }
    }, [eventoSeleccionado, fetchCambiarNombrePaso, irAlPasoDos, idVendedor, fetchDetalleCompra]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const vendedor = params.get('v')
        if (vendedor) {
            setVendedor(vendedor)
        } else {
            setVendedor(null);
        }
    }, [setVendedor])

    useEffect(() => {
        //seteamos og de facebook.
        if(eventoSeleccionado){
            setOgValues(lang, 'article', null, eventoSeleccionado.description, eventoSeleccionado.link, eventoSeleccionado.image, eventoSeleccionado.title)
        }
        if (eventoSeleccionado && pasoActualCompra !== "1") {
            const urlParams = new URLSearchParams(window.location.search);
            const typeParam = urlParams.get('type');
            let addParam = ''
            if (typeParam) {
                addParam = `?type=${typeParam}`
                const newUrl = `${window.location.pathname}${addParam}`;
                window.history.replaceState({}, '', newUrl);
            }
        }
    }, [pasoActualCompra, eventoSeleccionado, navigate])

      useEffect(() => {
        if (localStorage.getItem('token')) {
            if (pasoCompra === "1") {
                resetPasosCompra()

            } else if (pasoCompra === "2") {
                irAlPasoDos(parseJWt(localStorage.getItem('token')!).sessionEventId, idVendedor, null, null)

            } else if (pasoCompra === "3") {
                irAlPasoTres()
            }
            else if (pasoCompra === "4") {
                const urlParams = new URLSearchParams(window.location.search);
                const typeParam = urlParams.get('type');
                let addParam = 'debitCreditCard'
                if (typeParam) {
                    addParam = typeParam
                }
                irAlPasoCuatro(addParam);
            }
        } else {
            const hash = window.location.pathname.split("/")[2];
            if(window.location.pathname.split("/").slice(-1)[0] !== "1"){
                navigate(`/evento/${hash}/step/1`)
            }
        }
        detect();
    }, [pasoCompra, idVendedor, irAlPasoCuatro, irAlPasoDos, irAlPasoTres, navigate, resetPasosCompra]);


     const detect = () =>{
        setTimeout(()=>{
            window.scrollTo(0, 0);
        },500)

    }

    return (
        <Container>
            {!timeOut && <>
                {eventoSeleccionado && <div className='evento-seleccionado'>
                    <PasosCompra />
                    { pasoActualCompra !== "1" && eventoSeleccionado && <BarraSuperior /> }
                    {pasoActualCompra === "1" && <PasoUnoEvento />}
                    {pasoActualCompra === "2" && <PasoDosEvento setTimeOut={setElTimeOut} />}
                    {pasoActualCompra === "3" && detalleCompra && <PasoTresEvento setTimeOut={setElTimeOut} />}
                    {pasoActualCompra === "4" && detalleCompra && <PasoCuatroEvento setTimeOut={setElTimeOut} />}
                </div>}
                {fueraDeVenta && <FueraDeVenta />}
            </>}
            {timeOut && <Timeout setTimeout={setElTimeOut} />}
        </Container>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
        pasoActualCompra: state.eventsReducer.pasoActualCompra,
        idVendedor: state.eventsReducer.idVendedor,
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra,
        fueraDeVenta: state.eventsReducer.fueraDeVenta
    }
}

export default connect(
    mapStateToProps,
    { fetchEventoSeleccionado, fetchCambiarNombrePaso, setVendedor, irAlPasoDos, resetPasosCompra, irAlPasoTres, fetchDetalleCompra, irAlPasoCuatro }
)(EventoSeleccionado);
