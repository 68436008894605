import React, { useState, Dispatch, useEffect } from 'react';
import { Issuer, installmentData } from '../../../types/types';
import { connect } from 'react-redux';
import TarjetaDeCredito from './TarjetaDeCredito';
import { proccessPaymentMercadoPago } from '../../../api/purchase';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { sellerProccessPaymentMercadoPago } from '../../../api/transfer';

const MercadoPago = ({ cardNumber, installments, issuer, cardNumberHandle, purchaseCode }:
  {
    cardNumber: string,
    installments: installmentData[],
    issuer: Issuer | null,
    cardNumberHandle: Dispatch<string>,
    purchaseCode: string | null
  }) => {

  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    cardNumber: cardNumber,
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
    HOLDER_NAME: '',
    DOCUMENT: '',
    EMAIL: '',
  });
  const [cuotas, setCuotas] = useState(1);
  const [buttonState, setButtonState] = useState({ text: 'PAGAR', enable: true });
  const [modal, setModal] = useState<boolean>(false);
  const [errorMessage, seterrorMessage] = useState<string | null>(null);
  const [mercadoPago, setMercadoPagoObjet] = useState<any|null>(null);
  useEffect(() => {
    const mercadopago = new (window as any).MercadoPago('APP_USR-12497abe-d74a-4412-b3ac-3026dd637c69');
    setMercadoPagoObjet(mercadopago);
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    if(value.length > Number(event.target.getAttribute('max'))){
      return
     }
    let str: any;
    if (name === 'cardNumber') {
      var pattern = /^([0-9]+)?$/;
      if (pattern.test(value)) {
        str = value;
      } else {
        return;
      }
    } else {
      str = value;
    }
    setFormState((prevState) => ({
      ...prevState,
      [name]: str,
    }));
  };

  const toggle = () => {
      setModal(!modal);
  }
  const submitHandler = async () => {
    setButtonState({ text: "Procesando...", enable: false });
    const tokenData = {
      cardholderName: formState.HOLDER_NAME,
      cardNumber: formState.cardNumber,
      cardExpirationMonth: formState.expirationMonth,
      cardExpirationYear: `20${formState.expirationYear}`,
      securityCode: formState.securityCode,
      docType: 'DNI',
      docNumber: formState.DOCUMENT,
      issuer: issuer ? issuer.id : null,
      installments: cuotas
    }; 

    const { id: token, error } = await mercadoPago.createCardToken(tokenData);

    if(error){
      const errorObject = {
        mensajeAMostrar: error
      };
      seterrorMessage(errorObject.mensajeAMostrar);
      toggle();
      setButtonState({ text: "PAGAR", enable: true });
      return;
    }
      
      
    // llamada al back
    const formData = {
      issuer_id: issuer ? issuer.id : null,
      installments: cuotas,
      token: token
    };

    if (purchaseCode) {
      sellerProccessPaymentMercadoPago(formData, purchaseCode)
      .then((res) => {
        if(res.result) {
          navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
        } else {
          seterrorMessage(res!.message);
          toggle();
          setButtonState({ text: "PAGAR", enable: true });
        }
      })
      .catch((error) => {
        seterrorMessage(error!.message);
        toggle();
        setButtonState({ text: "PAGAR", enable: true });
      });
    } else {

      proccessPaymentMercadoPago(formData)
        .then((res) => {
          if(res.result) {
            navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
          } else {
            seterrorMessage(res!.message);
            toggle();
            setButtonState({ text: "PAGAR", enable: true });
          }
        })
        .catch((error) => {
          seterrorMessage(error!.message);
          toggle();
          setButtonState({ text: "PAGAR", enable: true });
        });
    }
  
  };


  const validateCardNumber = (currentCardNumber: string): void => {
      cardNumberHandle(currentCardNumber);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader toggle={toggle}>ERROR DE PAGO</ModalHeader>
          <ModalBody className='error-handler'>
              <p className='text-center error-message'>{errorMessage}</p>
              <div className='d-flext justify-content-center p-0 mb-2'>
                  <Row className='text-center'>
                      <Col md={12} className='pb-2 pt-2'>
                          <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggle(); }}>CERRAR </Button>
                      </Col>
                  </Row>
              </div>
          </ModalBody>
      </Modal>
      <TarjetaDeCredito
        purchaseCode={purchaseCode}
        formState={formState}
        handleInputChange={handleInputChange}
        submitHandler={submitHandler}
        installments={installments}
        cuotas={cuotas}
        setCuotas={setCuotas}
        buttonState={buttonState}
        validateCardNumber={validateCardNumber} 
        issuer={issuer}
        />
    </>
    
  );
};

export default connect(
  null,
  { }
)(MercadoPago);
