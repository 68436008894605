import { useEffect, useState } from 'react';
import { fetchEventoSeleccionado, fetchCambiarNombrePaso, setVendedor, irAlPasoDos, resetPasosCompra, irAlPasoTres, fetchDetalleCompra, irAlPasoCuatro, irAlPasoDosPago } from '../../reducer/events-reducer';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { eventoSeleccionado, datosCompra, PurchaseData } from '../../types/types';
import '../sass/evento_seleccionado.scss';
import '../sass/general.scss';
import {
    Container,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Timeout from '../components/compra/Timeout';
import { parseJWt } from '../../utilities/utilities';
import BarraSuperior from '../components/eventos/BarraSuperior';
import FueraDeVenta from '../components/eventos/FueraDeVenta';
import { getLangValue, setOgValues } from '../../api/config';
import PasosPago from '../components/pago/pasosPago';
import PasoUnoPago from '../components/pago/PasoUnoPago';
import PasoDosPago from '../components/pago/PasoDosPago';
import PasoTresPago from '../components/pago/PasoTresPago';

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
        pasoActualCompra: string;
        idVendedor: string;
        datosCompraPasoDos: PurchaseData;
        detalleCompra: datosCompra,
        fueraDeVenta: boolean
    }
}
interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    fetchEventoSeleccionado: (hashEvent: string) => void;
    fetchCambiarNombrePaso: (init: boolean) => void;
    setVendedor: (vendedor: string | null) => void;
    pasoActualCompra: string,
    idVendedor: string,
    irAlPasoDos: (code: string, seller: string, referrer: string | null, cardNumber: string | null, onlyReload?: boolean | undefined) => {};
    resetPasosCompra: () => void;
    datosCompraPasoDos: PurchaseData;
    irAlPasoTres: () => {};
    detalleCompra: datosCompra
    fetchDetalleCompra: () => any
    irAlPasoCuatro: (paymentType: string) => void;
    fueraDeVenta:boolean;
    irAlPasoDosPago: () => void;
}

const DeudaSeleccionadaSeleccionado = (props: propiedades) => {
    const { hashEvent, pasoCompra } = useParams();
    const [timeOut, setElTimeOut] = useState<boolean>(false);
    const { fetchEventoSeleccionado, eventoSeleccionado, fetchCambiarNombrePaso, setVendedor, pasoActualCompra, idVendedor, irAlPasoDos, resetPasosCompra, irAlPasoTres, detalleCompra, fetchDetalleCompra, irAlPasoCuatro, fueraDeVenta, irAlPasoDosPago } = props;
    const navigate = useNavigate();
    const lang = getLangValue('lang');

    useEffect(() => {
        fetchEventoSeleccionado(hashEvent!)
    }, [fetchEventoSeleccionado, hashEvent])

    useEffect(() => {
        if (eventoSeleccionado && eventoSeleccionado.sessions && eventoSeleccionado.sessions.length > 1) {
            fetchCambiarNombrePaso(true);

        } else if (eventoSeleccionado && eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1) {
            fetchCambiarNombrePaso(false);
        }
        if (window.location.pathname.split('/step/')[1] === '3' || window.location.pathname.split('/step/')[1] === '4') {
            fetchDetalleCompra()
        }

    }, [eventoSeleccionado, fetchCambiarNombrePaso, irAlPasoDos, idVendedor, fetchDetalleCompra]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const vendedor = params.get('v')
        if (vendedor) {
            setVendedor(vendedor)
        } else {
            setVendedor(null);
        }
    }, [setVendedor])

    useEffect(() => {
         if (eventoSeleccionado && pasoActualCompra !== "1") {
            const urlParams = new URLSearchParams(window.location.search);
            const typeParam = urlParams.get('type');
            let addParam = ''
            if (typeParam) {
                addParam = `?type=${typeParam}`
                const newUrl = `${window.location.pathname}${addParam}`;
                window.history.replaceState({}, '', newUrl);
            }
        }
    }, [pasoActualCompra, eventoSeleccionado, navigate])

      useEffect(() => {
        if (localStorage.getItem('token')) {
            if (pasoCompra === "1") {
                resetPasosCompra()

            } else if (pasoCompra === "2") {
                irAlPasoDosPago()
                fetchDetalleCompra()
            }
            else if (pasoCompra === "3") {
                const urlParams = new URLSearchParams(window.location.search);
                const typeParam = urlParams.get('type');
                let addParam = 'debitCreditCard'
                if (typeParam) {
                    addParam = typeParam
                }
                irAlPasoTres();
            }
        } else {
            const hash = window.location.pathname.split("/")[2];
            if(window.location.pathname.split("/").slice(-1)[0] !== "1"){
                navigate(`/evento/${hash}/step/1`)
            }
        }
        detect();
    }, [pasoCompra, idVendedor, irAlPasoCuatro, irAlPasoDos, irAlPasoTres, navigate, resetPasosCompra]);


     const detect = () =>{
        setTimeout(()=>{
            window.scrollTo(0, 0);
        },500)

    }

    return (
        <Container>
            {!timeOut && <>
                {eventoSeleccionado && <div className='evento-seleccionado'>
                    <PasosPago />
                    {pasoActualCompra === "1" && <PasoUnoPago />}
                    {pasoActualCompra === "2" && detalleCompra && <PasoDosPago setTimeOut={setElTimeOut} />}
                    {pasoActualCompra === "3" && detalleCompra && <PasoTresPago setTimeOut={setElTimeOut} />}
                </div>}
                {fueraDeVenta && <FueraDeVenta />}
            </>}
            {timeOut && <Timeout setTimeout={setElTimeOut} />}
        </Container>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
        pasoActualCompra: state.eventsReducer.pasoActualCompra,
        idVendedor: state.eventsReducer.idVendedor,
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra,
        fueraDeVenta: state.eventsReducer.fueraDeVenta
    }
}

export default connect(
    mapStateToProps,
    { fetchEventoSeleccionado, fetchCambiarNombrePaso, setVendedor, irAlPasoDos, resetPasosCompra, irAlPasoTres, fetchDetalleCompra, irAlPasoCuatro, irAlPasoDosPago }
)(DeudaSeleccionadaSeleccionado);
