import React, { Dispatch, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { eventoSeleccionado, GenericObject } from '../../../types/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { parseJWt } from '../../../utilities/utilities';
import '../../sass/evento_seleccionado/paso_uno.scss';
import { getDebtByEvent } from '../../../api/purchase'
import { useNavigate } from 'react-router-dom';
import { getLangValue } from '../../../api/config';
import TextField from '../TextField';
import { setCard } from '../../../reducer/events-reducer';
import { mostrarError } from '../../../reducer/config-reducer';
import UbicacionFotos from '../eventos/UbicacionFotos';
interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    idVendedor: string,
    mostrarError:Dispatch<GenericObject | null>,
    setCard: (debtIdentificator: string | null) => void;
}

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
        idVendedor: string;
    }
}



const PasoUnoPago = (props: propiedades) => {
    const [horaSeleccionada, setHoraSeleccionada] = useState('')
    const navigate = useNavigate();
    const currentUrl = `${getLangValue("front")}/${window.location.pathname}`;
    
    const { eventoSeleccionado, idVendedor } = props;
    const [debtIdentificator, setDebtIdentificator] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (horaSeleccionada && localStorage.getItem('token')) {
            if (horaSeleccionada !== parseJWt(localStorage.getItem('token')!).sessionEventId) {
                localStorage.removeItem('token')
            }
        }
    }, [horaSeleccionada])

    const nextStep = (token: string) => {
        localStorage.setItem('token', token);
        const hash = window.location.pathname.split("/")[2];
        navigate(`/pagos/${hash}/step/2`)
    }

    const buyTicketHandler = async () => {
        const hash = window.location.pathname.split("/")[2];
        try {
            const res = await getDebtByEvent(hash, debtIdentificator);
            if (res) {
                localStorage.removeItem('referrer')
                nextStep(res.token)
            } else {
                setError("El Código de Pago ingresado, no existe o ya fue pagado");
            }
        } catch (error: any) {
            props.mostrarError({mensajeAMostrar: error!.message});
        }
    }
    const volverAtras = () => {
        navigate(`/`)
    }
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let str:string
        var pattern =  /^[a-zA-Z0-9]*$/;
            if (pattern.test(event.target.value)) {
                str = event.target.value;
            }else{
                return;
            }
        
        setDebtIdentificator(str);
    }

    return (
        <>
            <Row>
                <Col> <p className="h4 titulo_evento_en_paso text_2 mb-2" dangerouslySetInnerHTML={{ __html: eventoSeleccionado.title }} /></Col>
            </Row>
            {eventoSeleccionado.sessions && <Row className='row-info'>
                        {eventoSeleccionado.status !== "0" &&
                            <div className='buy-tickets'>
                                <h4 className='subtitles'>Ingresa Código de pago</h4>
                                <TextField required={true} type='text' id='codigoPago' label='Código de Pago' value={debtIdentificator ? debtIdentificator : ""} errorMsg={error} handleInputChange={handleInputChange} focusEvent={() => { setError(''); setDebtIdentificator('') }} />
                                <Button disabled={debtIdentificator === null || debtIdentificator === "" || debtIdentificator.length < 6} onClick={buyTicketHandler}>PAGAR &gt;</Button>
                            </div>}
                        {eventoSeleccionado.status === "0" && <Button className='back_2 p-3 ps-4 pe-4 mt-4 mb-2' onClick={volverAtras}> IR AL INICIO </Button>}
                    </Row>}
            <Row className='compra-paso-uno'>
                <Col className='text-start event-data'>
                    <hr className='hr-info' />
                    <h4 className='mt-2 subtitle'>Lugar</h4>
                    <UbicacionFotos
                        lugar={eventoSeleccionado.place}
                        address={eventoSeleccionado.address ? eventoSeleccionado.address : null}
                        map={eventoSeleccionado.map}
                        medialist={eventoSeleccionado.mediaList}
                        location={eventoSeleccionado.location}
                    />
                    {eventoSeleccionado.sessions && !eventoSeleccionado.description && <><hr className='hr-info' /></>}
                    {eventoSeleccionado.description && eventoSeleccionado.description !== '' && <>
                        <hr className='hr-info' />
                        <h4 className='mt-2 subtitle'>Info General</h4>
                        <Row className='row-info'>
                            <Col className='ps-4 mb-1'><div dangerouslySetInnerHTML={{ __html: eventoSeleccionado.description }} className='paso-description'></div></Col>
                        </Row>
                    </>}
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
        idVendedor: state.eventsReducer.idVendedor
    }
}

export default connect(
    mapStateToProps,
    {setCard, mostrarError}
)(PasoUnoPago);
