import React,{useEffect} from 'react';
import {Row, Col, Container} from 'reactstrap';
import { useParams } from 'react-router-dom';
import Cash from '../components/pagofinalizado/Cash';
import DebitCreditCard from '../components/pagofinalizado/DebitCreditCard';
import '../sass/pay_success.scss';

const PagoFinalizado = () => {
    const params = useParams();
    const {checkoutType, paymentMethod, additionalCode, eventType} = params;

    useEffect(()=>{
        localStorage.removeItem('token');
        setTimeout(()=>{
            window.scrollTo(0, 0);
        },500)
    },[])

    return (
        <Container className='text-center pay-success'>
            <div>
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <Row>
                            {checkoutType === 'cash' && <Col md={3} className='mt-0 d-flex justify-content-center'>
                                <img src="/images/confirmado.svg" alt="icono confirmado" className='img-fluid' />
                            </Col>}
                            {(checkoutType === 'debitCreditCard' || checkoutType === 'freePaid') && <Col md={3} style={{"marginTop" : "90px"}}>
                                <img src="/images/confirmado.svg" alt="icono confirmado" className='img-fluid' />
                            </Col>}
                            <Col md={9} className='d-flex justify-content-center flex-column text-start'>
                                {checkoutType === 'cash' && additionalCode && paymentMethod &&
                                    <Cash additionalCode={additionalCode} paymentMethod={paymentMethod} eventType={eventType ? Number(eventType): 0} />}
                                {(checkoutType === 'debitCreditCard' || checkoutType === 'freePaid') && 
                                    <DebitCreditCard additionalCode={additionalCode}/>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
           
        </Container>
    );
};

export default PagoFinalizado;