import React, {useEffect, useState, Dispatch, useCallback} from 'react';
import { Modal,  ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Alert } from 'reactstrap';
import "../sass/modalayuda.scss"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { isMobile } from 'react-device-detect';
import { getTelefonoAyuda } from '../../api/helpText';
import TextField from './TextField';
import reCAPTCHA from '../../utilities/reCAPTCHA';
import { getLangValue } from '../../api/config';
import { sendRequestForm } from '../../api/purchase';

interface telefonoAyuda {
    id:string,
    type:string,
    text:string
}

const ContactFormModal = ({handleContactFormModal}: {handleContactFormModal:Dispatch<void>}) => {
    const [modal, setModal] = useState(true);
    const [telefonoAyuda, setTelefonoAyuda] = useState<telefonoAyuda>({id:'', type: '',text: ''});
    const [recaptchaPrivateKey] = useState<string>(getLangValue("recaptcha_private_key"));
    const [recaptcha] = useState<reCAPTCHA>(new reCAPTCHA(recaptchaPrivateKey, "requestformsm"));
    const [isSuccess, setSuccess] = useState<boolean>(false);

    const toggle = () => {
        setModal(!modal);
        handleContactFormModal();
    }

    const [formState, setFormState] = useState({
        eventName: '',
        email: '',
        subject: '',
        question: ''
    });

    const [formStateError, setFormStateError] = useState({
        eventName: '',
        email: '',
        subject: '',
        question: ''
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        let str: any = value;
        setFormState(prevState => ({
            ...prevState,
            [name]: str,
        }));
    };

    const validateInput = async (name: string, value: string, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let error = '';

        if (event.target.required && (value === undefined || value.trim() === '')) {
            error = 'Complete el campo';
        } else if (value.length > parseInt(event.target.getAttribute('max') || '')) {
            error = `El campo debe tener como máximo ${event.target.getAttribute('max')} caracteres`;
        }

        if (name === 'email') {

            setFormState(prevState => ({
                ...prevState,
                [name]: value.trim(),
            }));

            if (value.trim() === '') {
                error = 'Complete el campo';
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                error = 'Ingrese un correo electrónico válido';
            }
        }

        setFormStateError(prevState => ({
            ...prevState,
            [name]: error,
        }));

        if (error !== '') {
            event.target.classList.add('has-error');
        } else {
            event.target.classList.remove('has-error');
        }
    };

    const isFormValid = () => {

        const requiredFields = document.querySelectorAll('input[required]') as NodeListOf<HTMLInputElement>;

        for (let i = 0; i < requiredFields.length; i++) {
            if (requiredFields[i].value.trim() === '') {
                return true;
            }
        }

        const requiredSelectFields = document.querySelectorAll('select[required]') as NodeListOf<HTMLSelectElement>;
        for (let i = 0; i < requiredSelectFields.length; i++) {
            if (requiredSelectFields[i].value === '') {
                return true;
            }
        }

        const formErrorValues = Object.values(formStateError);
        if (formErrorValues.some((value) => value !== '' && typeof (value) !== 'object')) {
            return true;
        }

        return false;
    };
    
    useEffect(()=>{
        
        if(modal){
            (async () => {
                const res = await getTelefonoAyuda();
                setTelefonoAyuda(res);
            })();
        }
    },[modal])

    const submitFormHandler = async () => {
        let token: string = await recaptcha.getToken();

        const request = {
            eventName: formState.eventName,
            email: formState.email,
            subject: formState.subject,
            question: formState.question,
            recaptchaToken: token
        };
        
        try {
            await sendRequestForm(request);
                setSuccess(true);
                //toggle();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal  className='ayuda modal-lg' isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader toggle={toggle}>Envíanos tu Consulta</ModalHeader>
          <ModalBody>

            {!isSuccess && <>
                <Row className='compra-paso-tres'>
                    <Col className='mb-3'>
                        <TextField required={true} type={'text'} value={formState.eventName} id={'eventName'} handleInputChange={handleInputChange} validate={validateInput} label='Nombre del Evento' errorMsg={formStateError.eventName} max={100} preventPasteEvent={true} spellcheck={false} />
                        <TextField required={true} type={'text'} value={formState.email} id={'email'} handleInputChange={handleInputChange} validate={validateInput} label='Tu Correo Electrónico' errorMsg={formStateError.email} max={50} preventPasteEvent={true} spellcheck={false} />
                        <TextField required={true} type={'text'} value={formState.subject} id={'subject'} handleInputChange={handleInputChange} validate={validateInput} label='Tema de Consulta' errorMsg={formStateError.subject} max={100} preventPasteEvent={true} spellcheck={false} />
                        <TextField required={true} type={'textarea'} otherProps={{'row':'5'}} value={formState.question} id={'question'} handleInputChange={handleInputChange} validate={validateInput} label='Detalle de la Consulta' errorMsg={formStateError.question} max={1000} preventPasteEvent={true} spellcheck={false} />
                    </Col>
                </Row>
                <Row className='d-flex mt-4 mb-3'>
                    <Col className='d-flex justify-content-between steps-buttons flex-column align-items-center'>
                        <Button disabled={isFormValid()} onClick={() => { submitFormHandler() }}> ENVIAR </Button>
                    </Col>
                </Row>
            </>}
            {isSuccess && 
                <>
                    <Row>
                        <Col>
                            <Alert className='alert-success'>Atenderemos tu solicitud lo mas pronto posible.</Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='d-flex justify-content-center mb-4 mt-4'><Button onClick={toggle}>CERRAR</Button></div>    
                        </Col>
                    </Row>
                </>
            }
          </ModalBody>
          <ModalFooter>
            <div className='ayuda-online bottom-0 w-100 text-center'>
                <div className='pe-3'>
                    <a target="_blank" href={isMobile ? `https://wa.me/${telefonoAyuda.text}` : `https://web.whatsapp.com/send?phone=${telefonoAyuda.text}`} rel="noreferrer">
                    <Button>Ayuda Online <WhatsAppIcon className='ms-1'/></Button>
                    </a>
                </div>
            </div>
          </ModalFooter>
        </Modal>
    );
};

export default ContactFormModal;
